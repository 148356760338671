<template>
  <div>
    <b-row align-h="center" style="margin-top: 10px; width: 100%">
      <b-form-group>
        <b-form-radio-group
          id="btn-radios-2"
          name="radio-btn-outline"
          v-model="columnsCount"
          buttons
          @change="changeColumns"
        >
          <b-form-radio value="1">1</b-form-radio>
          <b-form-radio value="2">2</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </b-row>
    <b-row :cols="columnsCount">
      <div
        v-for="(diagram, index) in defaultModule"
        :key="index"
        class="chart-modules"
      >
        <div class="chart-module-card" v-if="index < columnsCount">
          <div style="padding-bottom: 10px">
            <fp-select
              :id="index"
              :items="chartDiagram"
              :placeholder="
                $t('healthProfessional.patientList.diagramsTab.selectChart')
              "
              v-model="diagram.value"
              @change="changeChart(index)"
            >
              <template #element="chart">
                {{
                  chart && chart.Name ? $t("module.charts." + chart.Name) : null
                }}
              </template>
            </fp-select>
          </div>
          <div v-if="diagram.value">
            <!-- TODO: changeData bekötése a chartokon -->
            <component
              v-if="diagram.value.ComponentName"
              :Id="diagram.value.ComponentName + '-' + index"
              :is="diagram.value.ComponentName"
              :Interval="'Month'"
              :ChartType="'chart'"
              :PatientId="participant.UserId"
              :Chart="diagram.value"
              :changeData.sync="changeData"
              ref="chart-columns"
              :selectedEntryId="diagram.selectedEntryId"
            ></component>
            <!-- TODO: input gomb -->
            <div v-if="diagram.value.DiaryInputComponentName">
              <b-button
                @click="$set(diagram.value, '_showNewEntryModal', true)"
              >
                Új bejegyzés
              </b-button>
              <component
                :is="diagram.value.DiaryInputComponentName"
                v-model="diagram.value._showNewEntryModal"
                :TargetId="participant.UserId"
                @refreshList="changeData = true"
              />
            </div>
            <fp-client-module-diary
              :baseName="diagram.value.DatabaseName"
              :PatientId="participant.UserId"
              @selectEntryId="
                $set(defaultModule[index], 'selectedEntryId', $event)
              "
              :changeData.sync="changeData"
            />
          </div>
        </div>
      </div>
    </b-row>
  </div>
</template>
<script>
import { UserPreferencesLogic } from "../../../Logic/Backend/user-preferences";

export default {
  data() {
    return {
      avaliableChartDiagram: [
        { Name: "default" },
        {
          key: "Exercise",
          label: this.$t("module.modules.exerciseModule"),
          options: Object.values(
            UserPreferencesLogic.getEmptyModulesChartData("Exercise")
          ),
        },
        {
          key: "Cardiology",
          label: this.$t("module.modules.cardiologyModule"),
          options: Object.values(
            UserPreferencesLogic.getEmptyModulesChartData("Cardiology")
          ),
        },
        {
          key: "BodyWeight",
          label: this.$t("module.modules.bodyWeightModule"),
          options: Object.values(
            UserPreferencesLogic.getEmptyModulesChartData("BodyWeight")
          ),
        },
        {
          key: "Pulmonology",
          label: this.$t("module.modules.pulmonologyModule"),
          options: Object.values(
            UserPreferencesLogic.getEmptyModulesChartData("Pulmonology")
          ),
        },
        {
          key: "Medicine",
          label: this.$t("module.modules.medicineModule"),
          options: Object.values(
            UserPreferencesLogic.getEmptyModulesChartData("Medicine")
          ),
        },
        {
          key: "Depression",
          label: this.$t("module.modules.depressionModule"),
          options: Object.values(
            UserPreferencesLogic.getEmptyModulesChartData("Depression")
          ),
        },
        {
          key: "Diabetology",
          label: this.$t("module.modules.diabetologyModule"),
          options: Object.values(
            UserPreferencesLogic.getEmptyModulesChartData("Diabetology")
          ),
        },
        {
          key: "Nutrition",
          label: this.$t("module.modules.nutritionModule"),
          options: Object.values(
            UserPreferencesLogic.getEmptyModulesChartData("Nutrition")
          ),
        },
      ],
      chartDiagram: [{ Name: "default" }],
      defaultModule: [
        { value: null, selectedEntryId: null },
        { value: null, selectedEntryId: null },
      ],
      changeData: false,
      columnsCount: 1,
      selectedEntryId: null,
    };
  },
  props: {
    participant: Object,
    project: Object,
  },
  watch: {
    /* async */ participant() {
      this.defaultModule = [
        { value: null, selectedEntryId: null },
        { value: null, selectedEntryId: null },
      ];
      this.selectedEntryId = null;
      this.setChartList();
      //await this.getUserProfile();
    },
  },
  methods: {
    setChartList() {
      const activeModules = this.project.EnabledModules.HealthProfessional;
      if (activeModules) {
        this.avaliableChartDiagram.forEach((module) => {
          if (activeModules.includes(module.key)) {
            this.chartDiagram.push(module);
          }
        });
      } else {
        this.chartDiagram = this.avaliableChartDiagram;
      }
    },
    changeColumns() {
      this.defaultModule = [
        { value: null, selectedEntryId: null },
        { value: null, selectedEntryId: null },
      ];
      this.selectedEntryId = null;
    },
    changeChart(index) {
      this.$set(this.defaultModule[index], "selectedEntryId", null);
    },
  },
  mounted() {
    this.setChartList();
  },
};
</script>
<style scoped>
.chart-modules {
  padding: 10px;
}
</style>
